import React from "react"
import Collection from "../../components/Collection"
import { graphql } from "gatsby"

const lighting = ({ data, location}) => {
    // 1. Recupera collection_description dallo stato se disponibile
    const collectionDescriptionFromState = location.state?.collectionDescription;

    // 2. Fallback: Se collection_description non è presente nello stato, usa la query GraphQL
    const collectionDescriptionFromQuery = data.collectionData?.nodes[0]?.collection_description?.collection_description;

    // 3. Usa la descrizione dallo stato se esiste, altrimenti usa quella dalla query
    const collectionDescription = collectionDescriptionFromState || collectionDescriptionFromQuery || "";

    return (
    <div>
      <Collection
        pagination={"lighting"}
        data={data}
        title="Lighting"
        keywords={data.items.nodes[0].categoria}
        collectionDescription={collectionDescription}
      />
    </div>
  )
}

export const query = graphql`
  {
    items: allContentfulOggetto(
      filter: { categoria: { eq: "lighting" } }
      sort: { order: [ASC, ASC], fields: [sottocategoria, ordineDisposizione] }
    ) {
      nodes {
        titolo
        designer
        sold
        ordineDisposizione
        fotoAnteprima {
          fluid {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        slug: indirizzo
        sottocategoria
      }
    }
     collectionData: allContentfulCollection(
      filter: { title: { eq: "LIGHTING" } }
    ) {
      nodes {
        collection_description {
          collection_description
        }
      }
    }
  }
`

export default lighting
